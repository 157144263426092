import { LanguageCode } from '@/core/system';
import { TicketStatus, UploadedFile } from '@/modules/sts/common.models';

export type PlayerId = string;
export type TicketNumber = string;
export type MessageId = string | null;

export enum ReplyAuthorType {
  Player = 'player',
  Operator = 'operator',
  Robot = 'robot',
}

export interface TicketListItem {
  number: string;
  message: string;
  messageIsDeleted: boolean;
  lastReplyTimestamp: string;
  isClosed: boolean;
  lastChatTicketReply: ReplyAuthorType;
  isUnread: boolean;
  canFeedback: boolean;
}

export interface TicketChatMessage {
  id: MessageId;
  text: string;
  timestamp: string;
  attachments: UploadedFile[];
  author: ReplyAuthorType;
  canBeRated: boolean;
  messageWasHelpful: boolean | null;
  isDeleted: boolean;
}

export interface TicketChatFeedback {
  timestamp: string;
  score: number;
  message: string;
  solved?: boolean;
  optionalScores: { [key: string]: number };
}

export interface TicketView {
  messages: TicketChatMessage[];
  isClosed: boolean;
  allowFeedback: boolean;
  feedbacks: TicketChatFeedback[];
  status: TicketStatus;
  language: LanguageCode;
  closeReason: CloseReason;
}

export enum CloseReason {
  Undefined = 'undefined',
  Feedback = 'feedback',
  MessageWasHelpful = 'messageWasHelpful',
  TimeExpired = 'timeExpired',
  Rejected = 'rejected',
}
