








import { Component, Prop, Vue } from 'vue-property-decorator';
import { OnDestroyed, OnMounted } from '@/core/vue.types';
import debounce from 'lodash/debounce';
import smoothScroll from '@/themes/v1/fns/smooth-scroll';

@Component
export default class FixedPanel extends Vue implements OnMounted, OnDestroyed {
  @Prop({ type: Boolean, default: false })
  initScroll!: boolean;

  $refs!: {
    placeholder: HTMLDivElement;
    panel: HTMLDivElement;
  };

  mounted() {
    window.addEventListener('resize', this.onWindowResized);
    this.update(this.initScroll);
  }

  destroyed() {
    window.removeEventListener('resize', this.onWindowResized);
  }

  onWindowResized() {
    this.update();
  }

  onElementResized($event: CustomEvent) {
    this.update($event.detail.needScroll);
  }

  private update = debounce((needScroll = false) => {
    const panelHeight = this.$refs.panel.getBoundingClientRect().height;
    const placeholderHeight = this.$refs.placeholder.getBoundingClientRect()
      .height;
    if (panelHeight !== placeholderHeight) {
      this.$refs.placeholder.style.height = `${panelHeight}px`;
    }
    if (needScroll) {
      smoothScroll(document.body.scrollHeight);
    }
  }, 50);
}

export const emitBlockResized = function (
  element: HTMLElement,
  needScroll = false
) {
  if (element == null || element.dispatchEvent == undefined) {
    return;
  }
  const event = new CustomEvent('elementResized', {
    bubbles: true,
    detail: { needScroll },
  });
  element.dispatchEvent(event);
};
