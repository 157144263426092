<template>
  <div class="inline-ctrl-with-label">
    <div class="inline-ctrl-with-label__ctrl">
      <slot name="ctrl"></slot>
    </div>
    <div class="inline-ctrl-with-label__label">
      <slot name="label"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InlineCtrlWithLabel',
};
</script>

<style scoped>
.inline-ctrl-with-label {
  display: flex;
}
</style>
