







import { Component, Prop, Vue } from 'vue-property-decorator';
import Spinner from '@/themes/v1/components/Spinner.vue';

@Component({
  components: {
    Spinner,
  },
})
export default class GrayBox extends Vue {
  @Prop({ type: Boolean, default: false })
  loading!: boolean;
}
