






































import { Component, Vue } from 'vue-property-decorator';
import Popup from '@/themes/v1/components/Popup.vue';
import Box from '@/themes/v1/components/Box/Box.vue';
import BoxIcon from '@/themes/v1/components/Box/BoxIcon.vue';
import BoxContent from '@/themes/v1/components/Box/BoxContent.vue';
import ButtonsGroup from '@/themes/v1/components/ButtonsGroup.vue';
import TestLocator from '@/core/test-utils/test-locator';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const ClickOutside = require('vue-click-outside');

@Component({
  components: { ButtonsGroup, BoxContent, BoxIcon, Box, Popup },
  directives: { ClickOutside, TestLocator },
})
export default class DiscussionDeletePopup extends Vue {
  cancel() {
    this.$emit('cancel');
  }

  confirm() {
    this.$emit('confirm');
  }
}
