




import { Component, Vue, Prop } from 'vue-property-decorator';
import { ReplyAuthorType } from '@/modules/sts/chat';
import BgImage from '@/themes/v1/components/BgImage.vue';

@Component({
  components: { BgImage },
})
export default class Avatar extends Vue {
  @Prop({
    type: String,
    default: ReplyAuthorType.Operator,
    validator(val) {
      return Object.values(ReplyAuthorType).includes(val);
    },
  })
  type!: ReplyAuthorType;
}
